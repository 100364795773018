<template>
  <core-section id="lets-talk">
    <core-heading>Let's talk.</core-heading>

    <v-col
      cols="12"
      md="5"
      class="mb-4 pr-md-5"
    >
      <core-subheading class="text-uppercase mb-3">
        Send me a Message
      </core-subheading>

      <v-form>
        <v-text-field
          v-model="name"
          solo-inverted
          flat
          label="Name"
        />

        <v-text-field
          v-model="email"
          solo-inverted
          flat
          label="Email"
          :hint="hint"
          persistent-hint
        />

        <v-text-field
          v-model="subject"
          solo-inverted
          flat
          label="Subject"
        />

        <v-textarea
          v-model="message"
          solo-inverted
          flat
          label="Message"
        />

        <v-text-field
          v-model="honey"
          style="display: none"
        />

        <v-btn
          class="ma-0"
          color="primary"
          @click="emailSignUp"
        >
          Contact Me
        </v-btn>
      </v-form>
    </v-col>
    <v-col
      cols="12"
      md="7"
    >
      <v-img
        :src="require(`@/assets/conduct.jpg`)"
        height="400"
        width="600"
        contain
        class="mx-auto mt-12"
      />
      <!--
      <p>
        This site created by Kate and her Dad.
      </p>
      -->
    </v-col>
    <v-img
      :src="require('@/assets/john-alt.png')"
      contain
      max-width="30vw"
      style="bottom: -20px; right: 0; position: absolute;"
      width="100%"
    />
  </core-section>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'LetsTalk',
    data: () => ({
      hint: '',
      email: null,
      name: null,
      subject: null,
      message: null,
      honey: null,
    }),

    computed: {
      ...mapState('app', ['schema']),
    },

    methods: {
      emailSignUp: function () {
        if (/.+@.+\..+/.test(this.email)) {
          axios.post(
            'https://kate.kampner.com/bin/list.cgi',
            'email=' + this.email + '&name=' + this.name + '&subject=' + this.subject + '&message=' + this.message,
          ).then((response) => {
            if (response.data.success) {
              this.email = ''
              this.name = ''
              this.subject = ''
              this.message = ''
              this.hint = 'Thanks for your message!'
            } else {
              this.hint = 'Oops.  Something went wrong.'
            }
          },
          ).catch(function (error) {
            console.log(error)
          })
        } else {
          this.hint = 'Please enter a valid email address.'
        }
      },
    },
  }
</script>
